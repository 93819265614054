import React from "react";
import ContentWithFooter from "./layouts/ContentWithFooter";
import { Helmet } from "react-helmet";
import { pageTitle } from "../settings";

const Cookies = () => {
  return (
    <ContentWithFooter>
      <Helmet>
        <title>Cookies - {pageTitle}</title>
      </Helmet>
      <div className="max-w-3xl mx-auto text-base">
        <h1>Cookies</h1>
        <p>
          Cookies are used by most organisations to ensure their website run effectively and
          efficiently. Budget application utilize this technology too. What are cookies? A cookie is
          a small text file that saves on a user's computer or mobile device when they visit a
          website. It enables the website to remember the user's actions and preferences (such as
          login, language, font size and other display preferences) over a period of time. This
          saves them re-entering these details upon returning to the site or when browsing from one
          page to another.
        </p>
        <h1>How we use cookies?</h1>
        <p>
          We use cookies to store a user's preferences and settings, to gather web analytics, to
          authenticate users, and to detect fraud. How do third parties use cookies on the site? In
          some circumstances, we may work with third parties to provide services on the Budget
          services. For example, third-party analytics providers may use their own cookies to
          collect information about your activities on the Budget services. This information may be
          used by them to serve advertisements that they believe are most likely to be of interest
          to you based on content you have viewed. We do not control these cookies and to disable or
          reject third-party cookies, please refer to the relevant third party's website.
        </p>
        <h1>How to control cookies?</h1>
        <p>
          Users can control and/or delete cookies as they wish. For more information about this, see{" "}
          <a href="www.aboutcookies.org" rel="noopener noreferrer">
            www.aboutcookies.org
          </a>
          . User can delete all cookies stored on their computer and can set most browsers to
          prevent them from being placed. If a user does this however, they may have to manually
          adjust some preferences each time they visit our site. Furthermore, some website services
          and functionalities may not work.
        </p>
      </div>
    </ContentWithFooter>
  );
};

export default Cookies;
