import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useState, Children } from "react";
import "../../styles/carousel.scss";

interface CarouselProps {
  children: React.ReactNode;
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const lastIndex = React.Children.count(children) - 1;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === lastIndex ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? lastIndex : prevIndex - 1));
  };

  return (
    <div className="carousel">
      {currentIndex !== 0 && (
        <Icon
          icon={IconNames.ChevronLeft}
          size={64}
          className="carousel__btn carousel__btn--prev"
          onClick={handlePrev}
        />
      )}
      <div className="carousel__inner">
        <div
          style={{
            left: `-${currentIndex * 100}%`,
          }}
        >
          {Children.map(children, (child, index) => (
            <div className="carousel__item" key={index}>
              {child}
            </div>
          ))}
        </div>
      </div>
      {currentIndex !== lastIndex && (
        <Icon
          icon={IconNames.ChevronRight}
          size={64}
          className="carousel__btn carousel__btn--next"
          onClick={handleNext}
        />
      )}
    </div>
  );
};

export default Carousel;
