import { FORM_INPUTS } from "../../../utils/constants";
import { isRequired } from "../validators";

const budgetSectionSchema = {
  name: "forms.labels.budget_section",
  validators: {
    section_name: isRequired,
  },
  fields: {
    section_name: {
      required: true,
      label: "forms.labels.budget_section_name",
      type: FORM_INPUTS.TEXT,
    },
    order_number: {
      required: false,
      label: "forms.labels.order_number",
      type: FORM_INPUTS.NUMBER,
    },
  },
};

export default budgetSectionSchema;
