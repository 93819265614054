import gql from "graphql-tag";

export const CREATE_CATEGORY = gql`
  mutation createCategory($object: categories_insert_input!) {
    insert_categories_one(object: $object) {
      name
      id
      category_type
      budget_id
    }
  }
`;

export const CREATE_CATEGORIES = gql`
  mutation createCategories($objects: [categories_insert_input!]!) {
    insert_categories(objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($id: Int!, $name: String, $category_type: String) {
    update_categories_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, category_type: $category_type }
    ) {
      id
      name
      category_type
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: Int!) {
    delete_categories_by_pk(id: $id) {
      id
    }
  }
`;
