import gql from "graphql-tag";
import { BUDGET_ENTRY_FRAGMENT } from "../queries/budgetEntries";

export const CREATE_BUDGET_ENTRY = gql`
  ${BUDGET_ENTRY_FRAGMENT}
  mutation createBudgetEntry(
    $object: budget_entries_insert_input!
    $periodStart: timestamptz
    $periodEnd: timestamptz
  ) {
    insert_budget_entries_one(object: $object) {
      ...BudgetEntryFragment
    }
  }
`;

export const SOFT_DELETE_BUDGET_ENTRY = gql`
  ${BUDGET_ENTRY_FRAGMENT}
  mutation updateBudgetEntry(
    $id: Int!
    $deleted_at: timestamptz
    $periodStart: timestamptz
    $periodEnd: timestamptz
  ) {
    update_budget_entries_by_pk(pk_columns: { id: $id }, _set: { deleted_at: $deleted_at }) {
      ...BudgetEntryFragment
    }
  }
`;

export const UPDATE_BUDGET_ENTRY = gql`
  ${BUDGET_ENTRY_FRAGMENT}
  mutation updateBudgetEntry(
    $id: Int!
    $name: String
    $value: numeric
    $date: timestamptz
    $deleted_at: timestamptz
    $budget_section_id: Int
    $category_id: Int
    $periodStart: timestamptz
    $periodEnd: timestamptz
  ) {
    update_budget_entries_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        value: $value
        date: $date
        deleted_at: $deleted_at
        budget_section_id: $budget_section_id
        category_id: $category_id
      }
    ) {
      ...BudgetEntryFragment
    }
  }
`;

export const INLINE_UPDATE_BUDGET_ENTRY = gql`
  ${BUDGET_ENTRY_FRAGMENT}
  mutation UpdateBudgetEntries(
    $id: Int!
    $changes: budget_entries_set_input
    $periodStart: timestamptz
    $periodEnd: timestamptz
  ) {
    update_budget_entries_by_pk(pk_columns: { id: $id }, _set: $changes) {
      ...BudgetEntryFragment
    }
  }
`;

export const DELETE_BUDGET_ENTRY = gql`
  mutation deleteBudgetEntry($id: Int!) {
    delete_budget_entries_by_pk(id: $id) {
      id
    }
  }
`;
