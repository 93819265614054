import { Callout, Intent } from "@blueprintjs/core";
import React, { ErrorInfo, ReactNode } from "react";

interface IGlobalErrorBoundary {
  location: Location;
  children: ReactNode;
}

class GlobalErrorBoundary extends React.Component<IGlobalErrorBoundary, { hasError: boolean }> {
  constructor(props: IGlobalErrorBoundary) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps: IGlobalErrorBoundary) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  static getDerivedStateFromError(error: Error) {
    console.log(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Callout intent={Intent.DANGER}>Something went wrong, please try to refresh page</Callout>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default GlobalErrorBoundary;
