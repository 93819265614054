import gql from "graphql-tag";

export const CREATE_ESTIMATE = gql`
  mutation createEstimate($object: estimates_insert_input!) {
    insert_estimates_one(object: $object) {
      id
      name
    }
  }
`;

export const UPDATE_ESTIMATE = gql`
  mutation updateEstimate($id: Int!, $name: String) {
    update_estimates_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`;

export const DELETE_ESTIMATE = gql`
  mutation deleteEstimate($id: Int!) {
    delete_estimates_by_pk(id: $id) {
      id
    }
  }
`;
