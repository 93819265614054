import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { ItemListRenderer, ItemPredicate, ItemRenderer, Select2 } from "@blueprintjs/select";
import React from "react";
import currency from "../../assets/data/currency.json";
import { ICurrency } from "../../types/types";

const filterCurrency: ItemPredicate<ICurrency> = (query, currency, _index, exactMatch) => {
  const normalizedName = currency.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  } else {
    return `${normalizedName} ${currency.symbol}`.indexOf(normalizedQuery) >= 0;
  }
};

const renderCurrency: ItemRenderer<ICurrency> = (
  currency,
  { handleClick, handleFocus, modifiers, query }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={currency.cc}
      label={currency.symbol.toString()}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={`${currency.cc}. ${currency.name}`}
    />
  );
};

const itemListRenderer: ItemListRenderer<ICurrency> = ({
  items,
  itemsParentRef,
  query,
  renderItem,
  menuProps,
}) => {
  const renderedItems = items.map(renderItem).filter((item) => item != null);
  return (
    <Menu className="currency-selector-list" role="listbox" ulRef={itemsParentRef} {...menuProps}>
      <MenuItem
        disabled={true}
        text={`Found ${renderedItems.length} items matching "${query}"`}
        roleStructure="listoption"
      />
      {renderedItems}
    </Menu>
  );
};

interface ICurrencySelect {
  selected: ICurrency | undefined;
  onItemSelect: (
    item: ICurrency,
    event?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void;
}

const CurrencySelect = ({ onItemSelect, selected }: ICurrencySelect) => {
  return (
    <Select2<ICurrency>
      items={currency}
      itemPredicate={filterCurrency}
      itemRenderer={renderCurrency}
      itemListRenderer={itemListRenderer}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
      onItemSelect={onItemSelect}
    >
      <Button text={selected?.name} rightIcon="double-caret-vertical" />
    </Select2>
  );
};

export default CurrencySelect;
