import { isRequired, isNumber, combineValidators } from "../validators";
import { FORM_INPUTS } from "../../../utils/constants";

const goalSchema = {
  name: "forms.labels.goal",
  validators: {
    name: isRequired,
    initial_amount: combineValidators(isRequired, isNumber),
    target_amount: combineValidators(isRequired, isNumber),
  },
  fields: {
    name: {
      required: true,
      label: "forms.labels.account_name",
      // tooltip: "forms.tooltips.account_name",
      type: FORM_INPUTS.TEXT,
    },
    initial_amount: {
      required: true,
      label: "forms.labels.initial_amount",
      tooltip: "forms.tooltips.initial_amount",
      type: FORM_INPUTS.NUMBER,
    },
    target_amount: {
      required: true,
      label: "forms.labels.target_amount",
      tooltip: "forms.tooltips.target_amount",
      type: FORM_INPUTS.NUMBER,
    },
  },
};

export default goalSchema;
