import { create } from "zustand";

interface ITokenStore {
  token: string | null;
  setToken: (t: string) => void;
}

export const useTokenStore = create<ITokenStore>()((set) => ({
  token: null,
  setToken: (token: string) => set(() => ({ token })),
}));
