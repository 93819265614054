import { useMutation } from "@apollo/client";
import { Button, Card } from "@blueprintjs/core";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CREATE_TRIAL_SUBSCRIPTION } from "../graphql/mutations/subscription";
import { USER } from "../graphql/queries/user";
import { stripePaymentUrl, trialDaysCount } from "../settings";
import { TSubscriptionStatus } from "../types/types";
import { useSubscriptionData } from "./useSubscriptionData";
import { UserContext } from "./WithUserContext";

export const DollarIcon = ({ value }: { value: number }) => {
  return (
    <div
      className="flex flex-row items-center text-green-700 text-7xl justify-center"
      style={{ textShadow: "3px 3px 3px #aaa" }}
    >
      <strong>{value}$</strong>
    </div>
  );
};

export const SubscriptionTitle = ({ title }: { title: string }) => {
  return (
    <div className="my-12">
      <h2 className="font-bold text-xl md:text-3xl">{title}</h2>
    </div>
  );
};

export const ActionLink = ({ url, text }: { url: string; text: string }) => {
  return (
    <a
      className="bp5-button bp5-intent-success"
      href={url}
      target="_blank"
      rel="noreferrer noopener"
    >
      {text}
    </a>
  );
};

export const SubscriptionCard = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Card
      className={`flex flex-col flex-1 px-5 sm:px-1 lg:px-5 mx-1 xl:mx-4 rounded-lg items-center align-middle py-12 mb-8 ${className}`}
      elevation={3}
    >
      <div className="text-center text-gray-500 md:flex-1 md:flex-grow">
        <div className="text-center text-gray-500 h-full">{children}</div>
      </div>
    </Card>
  );
};

const Subscriptions = () => {
  const { t } = useTranslation();
  const userData = useContext(UserContext);
  const { status, subscriptionEnd, hasValidSubscription, trialEnd, trialEnded } =
    useSubscriptionData();

  const [createTrialSubscription] = useMutation(CREATE_TRIAL_SUBSCRIPTION, {
    refetchQueries: [
      {
        query: USER,
        variables: { userId: userData?.id },
      },
    ],
  });

  const createTrialSubscriptionCb = () => {
    const subscriptionStatus: TSubscriptionStatus = "trialing";

    createTrialSubscription({
      variables: {
        objects: [
          {
            purchased: DateTime.now().toJSDate(),
            status: subscriptionStatus,
            subscription_end: DateTime.now().plus({ days: trialDaysCount }).toJSDate(),
            email: userData?.email || userData?.name,
          },
        ],
      },
    }).catch((e) => console.error(e));
  };

  if (hasValidSubscription && status !== "trialing") {
    return (
      <div className="mt-0 md:mt-6">
        <SubscriptionCard>
          <div
            className="text-2xl"
            dangerouslySetInnerHTML={{
              __html: t("messages.you_have_a_valid_subscription", {
                date: subscriptionEnd.toLocaleString(),
              }),
            }}
          ></div>
        </SubscriptionCard>
      </div>
    );
  }

  return (
    <div className="mt-0 md:mt-5">
      {status === "trialing" && (
        <SubscriptionCard>
          {trialEnd && !trialEnded && (
            <div
              className="text-2xl"
              dangerouslySetInnerHTML={{
                __html: t("messages.your_trial_ends", {
                  date: subscriptionEnd.toLocaleString(),
                }),
              }}
            />
          )}
          {trialEnded && (
            <div
              className="text-2xl"
              dangerouslySetInnerHTML={{
                __html: t("messages.your_trial_has_ended"),
              }}
            />
          )}
        </SubscriptionCard>
      )}
      <div className="flex flex-col md:flex-row">
        {!status && (
          <SubscriptionCard>
            <div
              className="flex flex-row items-center text-green-700 text-7xl justify-center"
              style={{ textShadow: "3px 3px 3px #aaa" }}
            >
              <strong>&#9752;</strong>
            </div>
            <SubscriptionTitle
              title={t("messages.start_your_free__day_trial", { dayNo: trialDaysCount })}
            />
            <Button onClick={createTrialSubscriptionCb}>{`${t(
              "actions.start_free_trial"
            )}`}</Button>
          </SubscriptionCard>
        )}
        <SubscriptionCard>
          <DollarIcon value={1} />
          <SubscriptionTitle title={t("messages.one_month_subscription")} />
          <ActionLink url={stripePaymentUrl} text={t("actions.one_month_subscription")} />
        </SubscriptionCard>
        <SubscriptionCard>
          <DollarIcon value={10} />
          <SubscriptionTitle title={t("messages.one_year_subscription")} />
          <ActionLink url={stripePaymentUrl} text={t("actions.one_year_subscription")} />
        </SubscriptionCard>
      </div>
    </div>
  );
};

export default Subscriptions;
