import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidv4 } from "uuid";
import {
  Alignment,
  Button,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Navbar,
  Popover,
  Position,
  Switch,
  TextArea,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import FeedbackPlus from "feedbackplus/src/feedbackplus";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UPDATE_USER_SETTINGS } from "../../graphql/mutations/user";
import { USER } from "../../graphql/queries/user";
import { IUser } from "../../types/types";
import { UserContext } from "../WithUserContext";
import data from "../../../package.json";
import { backendUrl } from "../../settings";
import { isTestEnv, isWebViewFromAndroid, updateUserSettingsObject } from "../../utils/utils";
import InternalLink from "../common/InternalLink";
import { Notifications } from "../common/notifications";
import { useSubscriptionData } from "../useSubscriptionData";
import MobileLoginBtn from "../user/MobileLoginBtn";
import MobileLogoutBtn from "../user/MobileLogoutBtn";

const DashboardHeader = () => {
  const { t, i18n } = useTranslation();

  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS, {
    refetchQueries: [{ query: USER }],
  });
  const userData = useContext<IUser | undefined>(UserContext);
  const userSettings = userData?.settings as Record<string, any>;

  const hasBudgetCreated = !_.isEmpty(userData?.budgets);
  const { trialEnd } = useSubscriptionData();

  const [theme, setTheme] = useState(userSettings?.darkMode ? "dark" : "light");

  const [lng, setLng] = useState(userSettings?.lng ?? "en");

  const { logout, loginWithRedirect, isAuthenticated, user } = useAuth0();
  const [feedbackModalActive, setFeedbackModalActive] = useState(false);
  const onLogout = () => {
    logout();
  };

  const showFeedbackDialog = () => {
    const feedbackPlus = new FeedbackPlus();
    feedbackPlus
      .capture()
      .then(
        ({
          bitmap,
          width,
          height,
        }: {
          bitmap: CanvasImageSource;
          width: number;
          height: number;
        }) => {
          setFeedbackModalActive(true);
          _.defer(() => {
            const canvas: HTMLCanvasElement = document.getElementById(
              "canvas"
            ) as HTMLCanvasElement;
            if (canvas) {
              canvas.width = width;
              canvas.height = height;
              canvas.getContext("2d")?.drawImage(bitmap, 0, 0);
            } else {
              Notifications.show({
                message: t("errors.canvas_not_created"),
                intent: Intent.WARNING,
              });
            }
          });
        }
      )
      .catch(() => {
        Notifications.show({ message: t("errors.canvas_not_created"), intent: Intent.WARNING });
      });
  };

  const submitFeedback = useCallback(async () => {
    const canvas = document.getElementById("canvas");
    //@ts-ignore
    canvas?.toBlob(async (blobContent) => {
      const formData = new FormData();
      const text = document.getElementsByTagName("textarea")[0].value;

      const feedbackId = uuidv4().slice(0, 8);

      formData.append("attachment", blobContent);
      formData.append("text", text);
      formData.append("email", user?.email as string);
      formData.append("name", user?.name as string);
      formData.append("url", window.location.href);
      formData.append("lng", lng);
      formData.append("uuid", feedbackId);
      try {
        const response = await fetch(`${backendUrl}/send-email`, {
          method: "POST",
          headers: {},
          body: formData,
        });

        if (response.ok) {
          setFeedbackModalActive(false);

          Notifications.show({
            message: (
              <div
                dangerouslySetInnerHTML={{
                  __html: t("messages.feedback_sent", { id: feedbackId }),
                }}
              />
            ),
            intent: Intent.SUCCESS,
          });
        } else {
          Notifications.show({ message: t("errors.feedback_not_sent"), intent: Intent.WARNING });
        }
      } catch (err) {
        Notifications.show({ message: t("errors.feedback_not_sent"), intent: Intent.WARNING });
      }
    });
  }, []);

  const updateColors = useCallback((settings: Record<string, any>) => {
    if (settings.darkMode) {
      setTheme("dark");
      document.documentElement.classList.remove("light");
      document.documentElement.classList.add("bp5-dark");
      document.documentElement.classList.add("dark");
    } else {
      setTheme("light");
      document.documentElement.classList.remove("bp5-dark");
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");
    }
  }, []);

  const changeLng = useCallback(
    (lng: string) => () => {
      setLng(lng);
      i18n.changeLanguage(lng);
      updateUserSettings({
        variables: {
          id: user?.sub,
          settings: JSON.stringify(updateUserSettingsObject(userSettings, "lng", lng)),
        },
      });
    },
    [i18n, updateUserSettings, user, userSettings]
  );

  const toggleTheme = useCallback(() => {
    updateUserSettings({
      variables: {
        id: user?.sub,
        settings: JSON.stringify(
          updateUserSettingsObject(userSettings, "darkMode", theme !== "dark")
        ),
      },
    }).then((res) =>
      updateColors(JSON.parse(_.get(res, "data.update_users_by_pk.settings", "{}")))
    );
  }, [updateUserSettings, user, userSettings, theme, updateColors]);

  useEffect(() => {
    updateColors(userSettings);
  }, [userSettings, updateColors]);

  return (
    <div
      className="fixed w-full bg-black opacity-1 h-12"
      style={{
        top: isTestEnv() ? 48 : 0,
      }}
    >
      <Navbar className={`link-colors-orange block md:hidden ${Classes.DARK}`}>
        <Navbar.Group align={Alignment.LEFT}>
          <InternalLink to="/dashboard">{t("labels.budget")}</InternalLink>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Popover
            className=""
            content={
              <Menu>
                <MenuItem
                  tagName="div"
                  text={<InternalLink to={`/accounts`}>{t("labels.accounts")}</InternalLink>}
                />
                <MenuItem
                  tagName="div"
                  text={<InternalLink to={`/categories`}>{t("labels.categories")}</InternalLink>}
                />
                <MenuItem
                  tagName="div"
                  text={<InternalLink to={`/entries`}>{t("labels.entries")}</InternalLink>}
                />
                <MenuItem
                  tagName="div"
                  text={<InternalLink to="/estimates">{t("labels.estimates")}</InternalLink>}
                />
                {/* <MenuItem tagName="div" text={<InternalLink to={`/goals`}>{t("labels.my_goals")}</InternalLink>} /> */}
                <MenuItem
                  tagName="div"
                  text={<InternalLink to={`/payees`}>{t("labels.payees")}</InternalLink>}
                />
                <MenuItem
                  tagName="div"
                  text={<InternalLink to="/settings">{t("labels.settings")}</InternalLink>}
                />
                {userSettings.showDarkModeSwitch && (
                  <MenuItem
                    tagName="div"
                    text={
                      <Switch checked={theme === "dark"} label="Dark Mode" onChange={toggleTheme} />
                    }
                  />
                )}
                {isAuthenticated ? (
                  <>
                    <MenuItem
                      tagName="div"
                      className="bp5-minimal"
                      icon={IconNames.USER}
                      text={user?.email}
                    />
                    <MenuDivider />
                    {isWebViewFromAndroid() ? (
                      <MobileLogoutBtn />
                    ) : (
                      <MenuItem
                        tagName="div"
                        onClick={onLogout}
                        className="bp5-minimal"
                        icon={IconNames.LOG_OUT}
                        text={t("labels.logout")}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <MenuDivider />
                    {isWebViewFromAndroid() ? (
                      <MenuItem
                        tagName="div"
                        onClick={() => loginWithRedirect()}
                        className="bp5-minimal"
                        icon={IconNames.LOG_IN}
                        text={t("labels.log_in")}
                      />
                    ) : (
                      <MobileLoginBtn />
                    )}
                  </>
                )}
                <MenuDivider />
                <MenuItem
                  tagName="div"
                  text={<InternalLink to={`/contact`}>{t("labels.contact_us")}</InternalLink>}
                />
                <MenuItem
                  tagName="div"
                  className="bp5-minimal"
                  icon={IconNames.COG}
                  text={t("labels.version", { version: data.version })}
                />
              </Menu>
            }
            position={Position.BOTTOM}
          >
            <Button minimal outlined={false} icon={IconNames.MENU} />
          </Popover>
        </Navbar.Group>
      </Navbar>
      <Navbar className={`link-colors-orange hidden md:block ${Classes.DARK}`}>
        {hasBudgetCreated ? (
          <React.Fragment>
            <Navbar.Group align={Alignment.LEFT}>
              <Navbar.Heading>
                <InternalLink to="/dashboard">{t("labels.budget")}</InternalLink>
              </Navbar.Heading>
              <Navbar.Divider />
            </Navbar.Group>
            <Navbar.Group align={Alignment.LEFT}>
              <Navbar.Heading>
                <InternalLink to={`/accounts`}>{t("labels.accounts")}</InternalLink>
              </Navbar.Heading>
              <Navbar.Heading>
                <InternalLink to={`/categories`}>{t("labels.categories")}</InternalLink>
              </Navbar.Heading>
              <Navbar.Heading>
                <InternalLink to={`/entries`}>{t("labels.entries")}</InternalLink>
              </Navbar.Heading>
              <Navbar.Heading>
                <InternalLink to={`/payees`}>{t("labels.payees")}</InternalLink>
              </Navbar.Heading>
              <Navbar.Heading>
                <InternalLink to="/estimates">{t("labels.estimates")}</InternalLink>
              </Navbar.Heading>
              {/* <Navbar.Heading>
                <InternalLink to="/goals">{t("labels.goals")}</InternalLink>
              </Navbar.Heading> */}
            </Navbar.Group>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Navbar.Group align={Alignment.LEFT}>
              <Navbar.Heading>
                <InternalLink to="/dashboard">{t("labels.budget")}</InternalLink>
              </Navbar.Heading>
              <Navbar.Divider />
            </Navbar.Group>
          </React.Fragment>
        )}
        <Navbar.Group align={Alignment.RIGHT}>
          {isAuthenticated ? (
            <Popover
              position={Position.BOTTOM}
              content={
                <Menu>
                  <MenuItem
                    text={
                      <InternalLink to="/settings">{t("labels.profile_and_settings")}</InternalLink>
                    }
                  />
                  {/* <MenuItem text={<InternalLink to="/help">{t("labels.help")}</InternalLink>} /> */}
                  <MenuDivider />
                  {trialEnd ? (
                    <MenuItem
                      disabled
                      text={
                        <div>
                          {t("labels.trial")} - {t("labels.days_left")}: {trialEnd}
                          <Icon icon={IconNames.FLAME} />
                        </div>
                      }
                    />
                  ) : null}
                  {userSettings.showDarkModeSwitch && (
                    <React.Fragment>
                      <MenuItem
                        disabled
                        text={
                          <Switch
                            className="mb-0 text-xs"
                            checked={theme === "dark"}
                            label={t("labels.dark_mode")}
                            onChange={toggleTheme}
                          />
                        }
                      />

                      <MenuDivider />
                    </React.Fragment>
                  )}
                  <MenuItem onClick={changeLng("pl")} text={"PL"} />
                  <MenuItem onClick={changeLng("en")} text={"EN"} />
                  <MenuDivider />
                  {isWebViewFromAndroid() ? (
                    <MobileLogoutBtn />
                  ) : (
                    <MenuItem
                      className="bp5-minimal"
                      onClick={onLogout}
                      icon={IconNames.LOG_OUT}
                      text={t("labels.logout")}
                    />
                  )}
                  <MenuDivider />
                  <MenuItem
                    tagName="div"
                    className="bp5-minimal"
                    icon={IconNames.CAMERA}
                    onClick={showFeedbackDialog}
                    text={t("labels.feedback")}
                  />

                  <MenuDivider />
                  <MenuItem
                    tagName="div"
                    className="bp5-minimal"
                    icon={IconNames.COG}
                    text={t("labels.version", { version: data.version })}
                  />
                </Menu>
              }
            >
              <div className="cursor-pointer">
                {user?.picture ? (
                  <img className="w-8 inline-block" src={user?.picture} alt="" />
                ) : (
                  <Icon icon={IconNames.USER} />
                )}
                <span className="inline-block ml-2">{user?.email}</span>
                {trialEnd ? <Icon icon={IconNames.FLAME} /> : null}
                <Icon icon={IconNames.CHEVRON_DOWN} />
              </div>
            </Popover>
          ) : (
            <Button
              className="bp5-minimal"
              onClick={() => loginWithRedirect()}
              text={t("labels.log_in")}
              icon={IconNames.LOG_IN}
            />
          )}
        </Navbar.Group>
      </Navbar>
      <Dialog
        className="bp5-dark"
        icon={IconNames.CAMERA}
        onClose={() => setFeedbackModalActive(false)}
        title={t("labels.feedback")}
        isOpen={feedbackModalActive}
      >
        <div className="mx-auto mt-5">
          <canvas id="canvas" className="max-w-md" />
        </div>
        <FormGroup label={t("labels.feedback")} className="p-5 w-full">
          <TextArea className="w-full" />
        </FormGroup>
        <div>
          <div className="my-5 flex flex-row justify-evenly">
            <Button
              className="bp5-dark"
              onClick={() => setFeedbackModalActive(false)}
              text={t("labels.close")}
              icon={IconNames.CROSS}
            />
            <Button
              intent={Intent.SUCCESS}
              className="bp5-dark"
              onClick={submitFeedback}
              text={t("labels.send")}
              icon={IconNames.CROSS}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

DashboardHeader.propTypes = {};

export default DashboardHeader;
