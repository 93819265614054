import { gql, useMutation } from "@apollo/client";
import { ICategory } from "../../types/types";
import { CREATE_CATEGORY, DELETE_CATEGORY } from "../mutations/category";
import { CATEGORIES } from "../queries/categories";

export const useDeleteCategory = () => {
  return useMutation(DELETE_CATEGORY, {
    update(cache, { data: { delete_categories_by_pk } }) {
      const data: { categories: ICategory[] } | null = cache.readQuery({ query: CATEGORIES });
      if (data) {
        const newCategoriesList = data.categories.filter(
          ({ id }) => id !== delete_categories_by_pk.id
        );
        cache.writeQuery({
          query: CATEGORIES,
          data: {
            categories: newCategoriesList,
          },
        });
      }
    },
  });
};

export const useCreateCategory = () => {
  return useMutation(CREATE_CATEGORY, {
    update(cache, { data: { insert_categories_one } }) {
      cache.modify({
        fields: {
          categories(existingCategories = []) {
            const newCategoryRef = cache.writeFragment({
              data: insert_categories_one,
              fragment: gql`
                fragment NewCategory on categories {
                  name
                  id
                  category_type
                  budget_id
                }
              `,
            });
            return [...existingCategories, newCategoryRef];
          },
        },
      });
    },
  });
};
