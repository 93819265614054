import { isRequired, isNumber, combineValidators } from "../validators";
import { FORM_INPUTS } from "../../../utils/constants";

const accountSchema = {
  name: "forms.labels.account",
  validators: {
    name: isRequired,
    initial_amount: combineValidators(isRequired, isNumber),
  },
  fields: {
    name: {
      required: true,
      label: "forms.labels.account_name",
      // tooltip: "forms.tooltips.account_name",
      type: FORM_INPUTS.TEXT,
    },
    initial_amount: {
      required: true,
      label: "forms.labels.initial_amount",
      tooltip: "forms.tooltips.initial_amount",
      type: FORM_INPUTS.NUMBER,
    },
    currency: {
      required: true,
      label: "forms.labels.currency",
      // tooltip: "forms.tooltips.currency",
      type: FORM_INPUTS.CURRENCY_SELECTOR,
    },
    main_account: {
      label: "forms.labels.is_this_main_account",
      // tooltip: "forms.tooltips.main_account",
      type: FORM_INPUTS.SWITCHER,
    },
  },
};

export default accountSchema;
