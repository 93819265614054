import gql from "graphql-tag";
import { ENTRY_FRAGMENT } from "../fragments/entries";

export const GOAL = gql`
  ${ENTRY_FRAGMENT}
  query Goal($accountId: Int!, $periodStart: timestamptz!, $periodEnd: timestamptz!) {
    accounts_by_pk(id: $accountId) {
      balance
      currency
      entries(
        order_by: { date: desc }
        where: { _and: [{ date: { _gt: $periodStart } }, { date: { _lt: $periodEnd } }] }
      ) {
        ...EntryFragment
      }
      id
      initial_amount
      main_account
      name
      target_amount
    }
  }
`;

export const GOALS_LIST = gql`
  query Goals {
    accounts(order_by: { name: asc }, where: { account_type: { _eq: "goal" } }) {
      balance
      currency
      id
      initial_amount
      main_account
      name
      target_amount
    }
  }
`;

export const GOALS = gql`
  ${ENTRY_FRAGMENT}
  query Goals {
    accounts(order_by: { name: asc }, where: { account_type: { _eq: "goal" } }) {
      balance
      currency
      entries(order_by: { date: desc }, limit: 1) {
        ...EntryFragment
      }
      id
      initial_amount
      main_account
      name
      target_amount
    }
  }
`;

export const GOALS_BY_NAME = gql`
  query GoalsByName($accountName: String) {
    accounts(where: { name: { _eq: $accountName } }) {
      currency
      id
      main_account
      balance

      name
    }
  }
`;
