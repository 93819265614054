import React, { ReactElement, useContext } from "react";
import { IUser, ICurrency } from "../../types/types";
import { UserContext } from "../WithUserContext";

interface Props {
  value: number;
  currency?: ICurrency;
}

export default function MoneyValue({ value, currency }: Props): ReactElement {
  const userData = useContext<IUser | undefined>(UserContext);
  return (
    <span>
      {value.toFixed(2)}
      {currency && userData?.settings.premiumFeatures
        ? currency.symbol
        : (userData?.settings?.currency as ICurrency)?.symbol}
    </span>
  );
}
