import Gradient from "javascript-color-gradient";
import { create } from "zustand";
import { COLOR_GRADIENT_RANGE } from "../utils/constants";

const positiveGradientArray = new Gradient()
  .setColorGradient("#165A36", "#eeffee")
  .setMidpoint(COLOR_GRADIENT_RANGE)
  .getColors();

const negativeGradientArray = new Gradient()
  .setColorGradient("#ffdddd", "#FF9980")
  .setMidpoint(COLOR_GRADIENT_RANGE)
  .getColors();

interface ColorGradient {
  minValue: number;
  maxValue: number;
  gradientArray: string[];
  setMinValue: (value: number) => void;
  setMaxValue: (value: number) => void;
}

export const usePositiveColorsGradient = create<ColorGradient>()((set) => ({
  minValue: 0,
  maxValue: 0,
  gradientArray: positiveGradientArray,
  setMinValue: (value: number) => set(() => ({ minValue: value })),
  setMaxValue: (value: number) => set(() => ({ maxValue: value })),
}));

export const useNegativeColorsGradient = create<ColorGradient>()((set) => ({
  minValue: 0,
  maxValue: 0,
  gradientArray: negativeGradientArray,
  setMinValue: (value: number) => set(() => ({ minValue: value })),
  setMaxValue: (value: number) => set(() => ({ maxValue: value })),
}));
