import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const PageContent = ({ children }: Props) => {
  return <div className="page-content">{children}</div>;
};

export default PageContent;
