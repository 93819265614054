import gql from "graphql-tag";
import { ENTRY_FRAGMENT } from "../fragments/entries";

export const CREATE_ENTRY = gql`
  ${ENTRY_FRAGMENT}
  mutation createEntry($object: entries_insert_input!) {
    insert_entries_one(object: $object) {
      ...EntryFragment
    }
  }
`;

export const UPDATE_ENTRY = gql`
  ${ENTRY_FRAGMENT}
  mutation updateEntry(
    $id: Int!
    $value: numeric
    $date: timestamptz
    $category_id: Int
    $account_id: Int
    $payee_id: Int
    $description: String
    $type: String
    $related_entry: Int
  ) {
    update_entries_by_pk(
      pk_columns: { id: $id }
      _set: {
        value: $value
        date: $date
        category_id: $category_id
        account_id: $account_id
        payee_id: $payee_id
        type: $type
        description: $description
        related_entry: $related_entry
      }
    ) {
      ...EntryFragment
    }
  }
`;

export const DELETE_ENTRY = gql`
  ${ENTRY_FRAGMENT}
  mutation deleteEntry($id: Int!) {
    delete_entries_by_pk(id: $id) {
      ...EntryFragment
    }
  }
`;

export const BALANCE_ENTRIES = gql`
  mutation upsertEntries($objects: [entries_insert_input!]!) {
    insert_entries(
      objects: $objects
      on_conflict: { constraint: entries_pkey, update_columns: [balance] }
    ) {
      affected_rows
    }
    __typename
  }
`;

export const INLINE_UPDATE_ENTRY = gql`
  ${ENTRY_FRAGMENT}
  mutation inlineUpdateEntry($id: Int!, $changes: entries_set_input) {
    update_entries_by_pk(pk_columns: { id: $id }, _set: $changes) {
      ...EntryFragment
    }
  }
`;
