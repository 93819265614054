import gql from "graphql-tag";

export const CREATE_PAYEE = gql`
  mutation createPayee($object: payees_insert_input!) {
    insert_payees_one(object: $object) {
      id
      name
    }
  }
`;

export const UPDATE_PAYEE = gql`
  mutation updatePayee($id: Int!, $name: String) {
    update_payees_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`;

export const DELETE_PAYEE = gql`
  mutation deletePayee($id: Int!) {
    delete_payees_by_pk(id: $id) {
      id
    }
  }
`;
