import { Callout } from "@blueprintjs/core";
import Footer from "../Footer";
import React from "react";

type ContentWithFooterProps = {
  children: React.ReactNode;
  className?: string;
};

const ContentWithFooter = ({ children, className = "" }: ContentWithFooterProps) => {
  return (
    <div className={`flex flex-col h-full ${className}`}>
      <div className="p-3 md:p-0 flex-grow">
        <Callout className="h-full">{children}</Callout>
      </div>
      <Footer />
    </div>
  );
};

export default ContentWithFooter;
