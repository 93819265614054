import React from "react";
import { IEstimateEntry } from "../../types/types";
import FormBuilder from "./FormBuilder";
import estimateEntrySchema from "./schemas/estimateEntry";

const EstimateForm = ({
  estimate,
  onApply,
  onCancel,
  onChange,
}: {
  estimate: IEstimateEntry;
  onApply: (estimate: IEstimateEntry) => Promise<any>;
  onCancel: () => void;
  onChange: (key: string, value: any) => void;
}) => {
  return (
    <div className="m-4">
      <FormBuilder
        formSchema={estimateEntrySchema}
        entity={estimate}
        onChange={onChange}
        onApply={onApply}
        onCancel={onCancel}
        additionalOptions={{}}
      />
    </div>
  );
};
export default EstimateForm;
