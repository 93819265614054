import {
  Alignment,
  Button,
  Colors,
  Divider,
  EditableText,
  FormGroup,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
} from "@blueprintjs/core";
import { DateInput3 } from "@blueprintjs/datetime2";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";
import { t } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { FormEvent, KeyboardEvent, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ICategory, IFormSchema, formField } from "../../types/types";
import { FORM_INPUTS } from "../../utils/constants";
import AccountSelect from "../common/AccountSelect";
import CategoriesSelect from "../common/CategoriesSelect";
import CurrencySelect from "../common/CurrencySelect";
import DialogFooter from "../common/DialogFooter";
import PayeeSelect from "../common/PayeeSelect";
import MultiEntries from "./MultiEntries";
import { TValidationResults, getValidationErrorMessage, validateForm } from "./validators";

//@ts-ignore
const renderMenu = ({ items, itemsParentRef, query, renderItem }) => {
  //@ts-ignore
  const renderedItems = items.map(renderItem).filter((item) => item != null);
  return (
    <Menu className="select-options-menu" ulRef={itemsParentRef}>
      {renderedItems}
    </Menu>
  );
};

//@ts-ignore
const renderItem = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem active={modifiers.active} key={item.id} text={item.name} onClick={handleClick} />
  );
};

interface IValueInput {
  entityKey: string;
  onChange: (k: string, value: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  value: number | string;
}

export const ValueInput = ({ entityKey, onChange, onKeyDown, value }: IValueInput) => {
  return (
    <InputGroup
      autoFocus
      value={value?.toString()}
      onKeyDown={onKeyDown || undefined}
      onChange={(e) => {
        const value = e.target.value
          .replace(",", ".")
          .replace(/[^0-9.]/g, "")
          .replace(/(\..*)\./g, "$1");
        onChange(entityKey, value);
      }}
    />
  );
};

export const getFieldLabel = (field: formField) => {
  return field.tooltip ? (
    <Tooltip usePortal content={<div>{i18n.t(field.tooltip) as string}</div>}>
      <div className="inline-block">
        {i18n.t(field.label) as string}
        <span className="ml-1 text-red-800">{field.required ? "*" : ""}</span>
      </div>
    </Tooltip>
  ) : (
    <div className="inline-block">
      {i18n.t(field.label) as string}
      <span className="ml-1 text-red-800">{field.required ? "*" : ""}</span>
    </div>
  );
};

//@ts-ignore
function fieldMapper(entity, validationResults, onChange, additionalOptions) {
  return (field: formField | formField[], key: string): ReactNode => {
    if (_.isArray(field)) {
      return (
        <div className="flex flex-col sm:flex-row">
          {_.map(field, (f, index) => {
            return (
              <React.Fragment>
                <div className="flex-0 sm:flex-1 ">
                  {fieldMapper(
                    entity,
                    validationResults,
                    onChange,
                    additionalOptions
                  )(f, f.key || "")}
                </div>
                {index !== field.length - 1 && <Divider className="mx-5" />}
              </React.Fragment>
            );
          })}
        </div>
      );
    } else {
      switch (field.type) {
        case FORM_INPUTS.TEXT: {
          return (
            <FormGroup
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              inline={field.inline || false}
              label={getFieldLabel(field)}
              className={key}
            >
              <InputGroup
                autoFocus
                value={entity[key]}
                onChange={(e) => onChange(key, e.target.value)}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.NUMBER: {
          return (
            <FormGroup
              inline={field.inline || false}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              label={getFieldLabel(field)}
              className={key}
            >
              <ValueInput key={key} value={entity[key]} entityKey={key || ""} onChange={onChange} />
            </FormGroup>
          );
        }
        case FORM_INPUTS.SELECT: {
          return (
            <FormGroup
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              inline={field.inline || false}
              label={getFieldLabel(field)}
              className={key}
            >
              <Select
                itemListRenderer={renderMenu}
                items={field.options as { value: string; text: string }[]}
                onItemSelect={(item) => onChange(key, item)}
                itemRenderer={renderItem}
              >
                <Button text={field.selectedOption} rightIcon={IconNames.DOUBLE_CARET_VERTICAL} />
              </Select>
            </FormGroup>
          );
        }
        case FORM_INPUTS.CATEGORIES_SELECT: {
          const options = additionalOptions[key] || {};
          const errorMsg = getValidationErrorMessage(validationResults, key);

          const helperText = errorMsg
            ? errorMsg
            : entity.transfer && entity.id
            ? i18n.t("messages.transfer_account_change_warning")
            : "";

          return (
            <FormGroup
              key={key}
              helperText={helperText}
              inline={field.inline || false}
              intent={errorMsg ? Intent.DANGER : helperText ? Intent.WARNING : Intent.NONE}
              disabled={entity.transfer && entity.id}
              label={getFieldLabel(field)}
              className={key}
            >
              <CategoriesSelect
                includeTransfers={true && !additionalOptions.isTransfer}
                isTransfer={additionalOptions.isTransfer || false}
                filterOutCategories={options.filterOutCategories || []}
                filterOutNestedCategories={options.filterOutNestedCategories}
                onChange={(category: ICategory) => onChange(key, category)}
                initialCategory={entity[key]}
                disabled={entity.transfer && entity.id}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.RICH_TEXT: {
          return (
            <FormGroup
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              inline={field.inline || false}
              label={getFieldLabel(field)}
              className={key}
            >
              <EditableText
                multiline={true}
                minLines={2}
                maxLines={4}
                value={entity[key]}
                onChange={(value) => onChange(key, value)}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.PAYEE_SELECT: {
          return (
            <FormGroup
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              inline={field.inline || false}
              label={getFieldLabel(field)}
              className={key}
            >
              <PayeeSelect
                disabled={additionalOptions.isTransfer || false}
                //@ts-ignore
                userId={additionalOptions.userId}
                //@ts-ignore
                onChange={(payee) => onChange(key, payee)}
                initialPayee={entity[key]}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.ACCOUNT_SELECT: {
          const errorMsg = getValidationErrorMessage(validationResults, key);
          const helperText = errorMsg
            ? errorMsg
            : entity.transfer && entity.id
            ? i18n.t("messages.transfer_account_change_warning")
            : "";
          return (
            <FormGroup
              key={key}
              inline={field.inline || false}
              intent={errorMsg ? Intent.DANGER : helperText ? Intent.WARNING : Intent.NONE}
              disabled={entity.transfer && entity.id}
              helperText={helperText}
              label={getFieldLabel(field)}
              className={key}
            >
              <AccountSelect
                //@ts-ignore
                disabled={entity.transfer && entity.id}
                onChange={(account) => onChange(key, account)}
                initialAccount={entity[key]}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.DATE_INPUT: {
          const errorMsg = getValidationErrorMessage(validationResults, key);

          const helperText = errorMsg
            ? errorMsg
            : entity.transfer && entity.id
            ? i18n.t("messages.transfer_date_change_warning")
            : "";

          return (
            <FormGroup
              inline={field.inline || false}
              key={key}
              intent={errorMsg ? Intent.DANGER : helperText ? Intent.WARNING : Intent.NONE}
              helperText={helperText}
              label={getFieldLabel(field)}
              className={key}
              disabled={entity.transfer && entity.id}
            >
              <DateInput3
                disabled={entity.transfer && entity.id}
                onChange={(date) => {
                  onChange(key, date);
                }}
                value={entity[key].toString()}
                formatDate={(date) => {
                  return DateTime.fromJSDate(date).toFormat("cccc dd/MM/yyyy HH:mm");
                }}
                parseDate={(str: string) => {
                  console.log({ str });
                  return new Date(str);
                }}
                placeholder="dd/MM/yyyy"
                timePrecision="minute"
                showTimezoneSelect={false}
                timePickerProps={{
                  showArrowButtons: true,
                }}
              />
            </FormGroup>
          );
        }
        case FORM_INPUTS.TRANSFER_SWITCH: {
          return (
            <React.Fragment>
              <Switch
                alignIndicator={Alignment.LEFT}
                key={key}
                disabled={!_.isEmpty(additionalOptions[key].disabled)}
                checked={entity["transfer"]}
                labelElement={getFieldLabel(field)}
                onChange={additionalOptions[key].onChange}
              />
              <Divider className="mb-6" />
            </React.Fragment>
          );
        }

        case FORM_INPUTS.RADIO_BUTTONS: {
          return (
            <FormGroup
              inline={field.inline || false}
              key={key}
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, key)}
                </div>
              }
              label={getFieldLabel(field)}
              className={key}
            >
              <RadioGroup
                inline={additionalOptions[key]?.inline}
                disabled={additionalOptions.isTransfer}
                onChange={(event: FormEvent<HTMLInputElement>) => {
                  onChange(key, event.currentTarget.value);
                }}
                selectedValue={entity[key] || field.defaultValue}
              >
                {_.map(field.options, (option, idx) => (
                  <Radio key={`${option}_${idx}`} label={t(option.text)} value={option.value} />
                ))}
              </RadioGroup>
            </FormGroup>
          );
        }

        case FORM_INPUTS.SWITCHER: {
          return (
            <Switch
              key={key}
              checked={entity[key]}
              labelElement={getFieldLabel(field)}
              //@ts-ignore
              onChange={(e) => onChange(key, e.target.checked)}
            />
          );
        }

        case FORM_INPUTS.MULTIENTRIES: {
          return (
            <FormGroup
              inline={field.inline || false}
              key={"splitted_entries"}
              label={getFieldLabel(field)}
              className={key}
            >
              <MultiEntries
                entity={entity}
                onChange={onChange}
                field={field}
                validationResults={validationResults}
              />
            </FormGroup>
          );
        }

        case FORM_INPUTS.CURRENCY_SELECTOR: {
          return (
            <FormGroup
              inline={field.inline || false}
              key="currency"
              helperText={
                <div style={{ color: Colors.RED2 }}>
                  {getValidationErrorMessage(validationResults, "splitted_entries")}
                </div>
              }
              label={getFieldLabel(field)}
              className={key}
            >
              <CurrencySelect
                selected={
                  _.isString(entity[key])
                    ? JSON.parse(_.isEmpty(entity[key]) ? "{}" : entity[key])
                    : entity[key]
                }
                onItemSelect={(selectedCurrency: any) => onChange("currency", selectedCurrency)}
              />
            </FormGroup>
          );
        }
      }
    }
  };
}

interface IFormBuilder {
  additionalOptions?: Record<any, any>;
  entity: object;
  formSchema: IFormSchema;
  onApply: any;
  onCancel: any;
  onChange: any;
}

const FormBuilder = ({
  additionalOptions = {},
  entity,
  formSchema,
  onApply,
  onCancel,
  onChange,
}: IFormBuilder) => {
  const [validationResults, setValidationResults] = useState<TValidationResults>({});

  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  //@ts-ignore
  const _onChange = (key, value) => {
    setValidationResults({ ...validationResults, [key]: [true, ""] });
    onChange(key, value);
  };
  //@ts-ignore
  const _onApply = (e) => {
    e.preventDefault();
    const vr = validateForm(entity, formSchema.validators);
    setValidationResults(vr);
    if (!_.isEmpty(vr)) return;
    setSaving(true);
    onApply(entity).finally(() => {
      setSaving(false);
    });
  };

  const isValidForm = _.every(validationResults, (result) => result[0]);

  return (
    <form onSubmit={_onApply}>
      {_.map(
        formSchema.fields,
        fieldMapper(entity, validationResults, _onChange, additionalOptions)
      )}
      <DialogFooter
        onCancel={onCancel}
        onApply={_onApply}
        applyLabel={t("labels.save")}
        applyEnabled={isValidForm}
        saving={saving}
      />
    </form>
  );
};

export default FormBuilder;
