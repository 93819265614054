import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React from "react";
import { IEntry } from "../../types/types";
import { ConfirmDialog } from "../common/ConfirmDialog";

interface IDialogProps {
  entryToBeDeleted?: IEntry;
  closeDeleteDialog: () => void;
  deleteDialogOpen: boolean;
  onDelete: (entry: IEntry) => void;
}

const EntryDeleteWarning = ({
  entryToBeDeleted,
  closeDeleteDialog,
  deleteDialogOpen,
  onDelete,
}: IDialogProps) => {
  return (
    <ConfirmDialog
      icon={IconNames.WARNING_SIGN}
      title="Delete entry warning"
      message={
        <div>
          Are you sure you want to remove this entry?
          <div>
            Category: <b>{entryToBeDeleted?.category?.name}</b>
          </div>
          <div>
            Value: <b>{entryToBeDeleted?.value}</b>
          </div>
        </div>
      }
      onCancel={closeDeleteDialog}
      isOpen={deleteDialogOpen && !_.isEmpty(entryToBeDeleted)}
      onApply={() => {
        onDelete(entryToBeDeleted as IEntry);
        closeDeleteDialog();
      }}
    />
  );
};

export default EntryDeleteWarning;
