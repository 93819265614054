import gql from "graphql-tag";

export const CREATE_BUDGET_SECTION = gql`
  mutation createBudgetSection($object: budget_sections_insert_input!) {
    insert_budget_sections_one(object: $object) {
      id
      section_name
      order_number
    }
  }
`;

export const UPDATE_BUDGET_SECTION = gql`
  mutation updateBudgetSection($id: Int!, $section_name: String, $order_number: Int) {
    update_budget_sections_by_pk(
      pk_columns: { id: $id }
      _set: { section_name: $section_name, order_number: $order_number }
    ) {
      id
      section_name
      order_number
    }
  }
`;

export const DELETE_BUDGET_SECTION = gql`
  mutation deleteBudgetSection($id: Int!) {
    delete_budget_sections_by_pk(id: $id) {
      id
    }
  }
`;
