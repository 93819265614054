import { DateTime } from "luxon";
import { create } from "zustand";

interface ISelectedDateStore {
  selectedDate: DateTime;
  setSelectedDate: (t: DateTime) => void;
}

export const useSelectedDateStore = create<ISelectedDateStore>()((set) => ({
  selectedDate: DateTime.now(),
  setSelectedDate: (selectedDate: DateTime) => set(() => ({ selectedDate })),
}));
