import React from "react";
import FormBuilder from "./FormBuilder";
import PayeeSchema from "./schemas/payee";
import { IPayee } from "../../types/types";

const PayeeForm = ({
  payee,
  onChange,
  onApply,
  onCancel,
}: {
  payee: IPayee;
  onApply: (payee: IPayee) => Promise<any>;
  onCancel: () => void;
  onChange: (key: string, value: any) => void;
}) => {
  return (
    <div className="m-4">
      <FormBuilder
        formSchema={PayeeSchema}
        entity={payee}
        onChange={onChange}
        onApply={onApply}
        onCancel={onCancel}
      />
    </div>
  );
};

PayeeForm.propTypes = {};

export default PayeeForm;
