import { FORM_INPUTS } from "../../../utils/constants";
import { isRequired } from "../validators";

const categorySchema = {
  name: "Category",
  validators: {
    name: isRequired,
  },
  fields: {
    name: {
      required: true,
      label: "forms.labels.category_name",
      type: FORM_INPUTS.TEXT,
    },
    // parentCategory: {
    //   required: false,
    //   label: "Parent category",
    //   type: FORM_INPUTS.CATEGORIES_SELECT,
    // },
    // category_type: {
    //   required: true,
    //   label: "Category type",
    //   type: FORM_INPUTS.RADIO_BUTTONS,
    //   defaultValue: ENTRY_TYPES.EXPENSE,
    //   options: [
    //     { value: ENTRY_TYPES.EXPENSE, text: "Expense" },
    //     { value: ENTRY_TYPES.INCOME, text: "Income" },
    //   ],
    // },
  },
};

export default categorySchema;
