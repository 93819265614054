import gql from "graphql-tag";
import { ENTRY_FRAGMENT } from "../fragments/entries.js";

export const ENTRIES = gql`
  ${ENTRY_FRAGMENT}
  query Entries($periodStart: timestamptz!, $periodEnd: timestamptz!) {
    entries(
      order_by: { date: desc }
      where: {
        _and: [
          { date: { _gt: $periodStart } }
          { date: { _lt: $periodEnd } }
          { _not: { deleted_at: { _is_null: false } } }
        ]
      }
    ) {
      ...EntryFragment
    }
  }
`;

export const ENTRIES_INCOMES = gql`
  ${ENTRY_FRAGMENT}
  query Entries($type: String = "income") {
    entries(
      type: $type
      order_by: { date: desc }
      where: { _and: [{ _not: { deleted_at: { _is_null: false } } }] }
    ) {
      ...EntryFragment
    }
  }
`;

export const ENTRIES_BY_ACCOUNT = gql`
  ${ENTRY_FRAGMENT}
  query Entries($accountId: Int!) {
    entries(where: { account_id: { _eq: $accountId } }, order_by: { date: desc }) {
      ...EntryFragment
    }
  }
`;

export const ENTRY = gql`
  ${ENTRY_FRAGMENT}
  query Entries($entryId: Int!) {
    entries_by_pk(id: $entryId) {
      ...EntryFragment
    }
  }
`;

export const ENTRIES_FROM_PERIOD = gql`
  ${ENTRY_FRAGMENT}
  query Entries($periodStart: timestamptz!, $periodEnd: timestamptz!) {
    entries(
      where: {
        _and: [
          { date: { _gt: $periodStart } }
          { date: { _lt: $periodEnd } }
          { _not: { deleted_at: { _is_null: false } } }
        ]
      }
    ) {
      ...EntryFragment
    }
  }
`;

export const FUTURE_ENTRIES = gql`
  ${ENTRY_FRAGMENT}
  query Entries($periodStart: timestamptz!, $accountId: Int!) {
    entries(
      order_by: { date: desc }
      where: {
        _and: [
          { account_id: { _eq: $accountId } }
          { date: { _gt: $periodStart } }
          { _not: { deleted_at: { _is_null: false } } }
        ]
      }
    ) {
      ...EntryFragment
    }
  }
`;

export const PREVIOUS_ENTRIES = gql`
  ${ENTRY_FRAGMENT}
  query Entries($periodStart: timestamptz!, $accountId: Int!) {
    entries(
      order_by: { date: desc }
      limit: 10
      where: {
        _and: [
          { account_id: { _eq: $accountId } }
          { date: { _lt: $periodStart } }
          { _not: { deleted_at: { _is_null: false } } }
        ]
      }
    ) {
      ...EntryFragment
    }
  }
`;
