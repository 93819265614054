import { isTestEnv, isStagingEnv, isWebViewFromAndroid } from "./utils/utils";

export const APP_ID = "org.budget4you";
export const domain =
  isTestEnv() || isStagingEnv()
    ? "dev-bnasnm42.eu.auth0.com"
    : // : isStagingEnv()
      // ? "budget4you-test.eu.auth0.com"
      "budget4you.eu.auth0.com";

export const clientId = isWebViewFromAndroid()
  ? "oamCZNtaSj2K3sebd0ana3WnvCpQsRjK"
  : isTestEnv() || isStagingEnv()
  ? "tfIV22weOdQwyoKcg1M9Q9eTuNCwjO5B"
  : // : isStagingEnv()
    // ? "dVu6uZOBHbHwfRwKTsumPyvBzN3ZrUgi"
    "ae5tkRBXquzDnfjRIazEpmxHezYTofAD";

export const redirectUri = `${window.location.origin}/dashboard`;
export const mobileRedirectUri = `${APP_ID}://budget4you.eu.auth0.com/capacitor/${APP_ID}/callback`;

export const audience =
  isTestEnv() || isStagingEnv()
    ? "budget-app"
    : // : isStagingEnv()
      // ? "https://budget4you-test.eu.auth0.com/api/v2/"
      "https://budget4you.eu.auth0.com/api/v2/";

export const authorizationParams = {
  audience: audience,
  redirect_uri: isWebViewFromAndroid() ? mobileRedirectUri : redirectUri,
};

export const graphqlUri = isTestEnv()
  ? "http://localhost:8080/v1/graphql"
  : // "https://budget.constexpr.pl/api/v1/graphql"
  isStagingEnv()
  ? "https://budget.constexpr.pl/api/v1/graphql"
  : "https://budget4you.pl/api/v1/graphql";

export const backendUrl = isTestEnv()
  ? "http://localhost:5000"
  : isStagingEnv()
  ? "https://budget.constexpr.pl/_backend"
  : "https://budget4you.pl/_backend";

export const stripePaymentUrl = isTestEnv()
  ? "https://buy.stripe.com/test_bIY16O11J8jW7hC5kk"
  : isStagingEnv()
  ? "https://buy.stripe.com/test_bIY16O11J8jW7hC5kk"
  : "https://buy.stripe.com/eVabKJ1HH3x9a5i000";

export const stripeYearPaymentUrl = isTestEnv()
  ? "https://buy.stripe.com/test_4gwdTAbGnbw86dy7su"
  : isStagingEnv()
  ? "https://buy.stripe.com/test_4gwdTAbGnbw86dy7su"
  : "https://buy.stripe.com/7sI9CB4TT6Jl2CQ6op";

export const trialDaysCount = 14;
export const supportEmail = "budget4you.cc@gmail.com";
export const x = "https://x.com/Budget4You";
export const instagram = "https://www.instagram.com/budget4you.cc/";
export const facebook = "https://www.facebook.com/people/Budget4You/61561864484046/";
export const pageTitle = "Budget4You";
