import React from "react";
import ContentWithFooter from "./layouts/ContentWithFooter";
import { useTranslation } from "react-i18next";
import { pageTitle, supportEmail } from "../settings";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <ContentWithFooter>
      <Helmet>
        <title>Privacy Policy - {pageTitle}</title>
      </Helmet>
      <h2 className="bp5-heading pt-12 pb-6 text-center">{t("labels.privacy_policy")}</h2>
      <div className="max-w-5xl mx-auto">
        <h3 className="bp5-heading py-2">1. Introduction</h3>
        <p className="pb-6 pt-4">
          We value your privacy and are committed to protecting your personal information. This
          Privacy Policy outlines how we collect, use, and protect your data when you use our
          services, including payment processing, authentication, and analytics.
        </p>
        <h3 className="bp5-heading py-2">2. Information We Collect</h3>
        <p className="pb-6 pt-4">
          <h4 className="bp5-heading py-2">2.1 Personal Information</h4>
          When you use our services, we may collect the following personal information: email,
          payment information
        </p>
        <p className="pb-6 pt-4">
          <h4 className="bp5-heading py-2">2.2 Usage Data</h4>
          We may also collect information about how you interact with our services, including: IP
          address Browser type and version Pages visited Time and date of visit
        </p>
        <h3 className="bp5-heading py-2">3. Use of Information</h3>
        <p className="pb-6 pt-4">
          <h4 className="bp5-heading py-2">3.1 Stripe for Payments</h4> We use Stripe to process
          payments securely. When you make a payment, your payment information is transmitted
          directly to Stripe and is not stored on our servers. Stripe collects and processes your
          payment information in accordance with their privacy policy, which you can review here.{" "}
        </p>
        <p className="pb-6 pt-4">
          <h4 className="bp5-heading py-2">3.2 Auth0 for Authentication</h4>We use Auth0 to manage
          user authentication and secure access to our services. Auth0 collects and stores your
          login credentials and authentication-related data in accordance with their privacy policy,
          which you can review here.
        </p>
        <p className="pb-6 pt-4">
          <h4 className="bp5-heading py-2">3.3 Google Analytics</h4>We use Google Analytics to help
          us understand how our users interact with our services. Google Analytics collects
          information such as your IP address, browser type, referring pages, and time spent on our
          site. This information is used to analyze trends, administer the site, track users'
          movements around the site, and gather demographic information. Google Analytics processes
          data in accordance with their privacy policy, which you can review here.
        </p>
        <h3 className="bp5-heading py-2">4. Sharing of Information</h3>
        <p className="pb-6 pt-4">
          We do not sell, trade, or otherwise transfer your personal information to outside parties
          except as described in this Privacy Policy. We may share your information with trusted
          third parties who assist us in operating our services, conducting our business, or serving
          our users, so long as those parties agree to keep this information confidential.
        </p>
        <h3 className="bp5-heading py-2">5. Data Security</h3>
        <p className="pb-6 pt-4">
          We implement a variety of security measures to maintain the safety of your personal
          information. These measures include encryption, access controls, and secure data storage
          practices. While we strive to protect your personal information, we cannot guarantee its
          absolute security.
        </p>
        <h3 className="bp5-heading py-2">6. Your Rights</h3>
        <p className="pb-6 pt-4">
          You have the right to access, correct, or delete your personal information. You may also
          object to or restrict the processing of your data. To exercise these rights, please
          contact us at <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
        </p>
        <h3 className="bp5-heading py-2">7. Changes to This Privacy Policy</h3>
        <p className="pb-6 pt-4">
          We may update this Privacy Policy from time to time. Any changes will be posted on this
          page, and the effective date will be updated accordingly. We encourage you to review this
          Privacy Policy periodically to stay informed about how we are protecting your information.
        </p>
        <h3 className="bp5-heading py-2">8. Contact Us</h3>
        <p className="pb-6 pt-4">
          If you have any questions about this Privacy Policy or our data practices, please contact
          us at: Email: <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          <br />
          By using our services, you consent to our Privacy Policy.
        </p>
      </div>
    </ContentWithFooter>
  );
};

export default PrivacyPolicy;
