import { isRequired } from "../validators";
import { FORM_INPUTS } from "../../../utils/constants";

const estimateSchema = {
  name: "forms.labels.estimate",
  validators: {
    name: isRequired,
    planned: isRequired,
    category: isRequired,
  },
  fields: {
    name: {
      required: true,
      label: "forms.labels.estimate_name",
      type: FORM_INPUTS.TEXT,
    },
    category: {
      label: "forms.labels.category",
      required: true,
      type: FORM_INPUTS.CATEGORIES_SELECT,
    },
    planned: {
      required: true,
      label: "forms.labels.planned",
      type: FORM_INPUTS.NUMBER,
    },
    real: {
      required: true,
      label: "forms.labels.real",
      type: FORM_INPUTS.NUMBER,
    },
  },
};

export default estimateSchema;
