import { Button, Intent, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { isWebViewFromAndroid } from "../../utils/utils";
import MobileLoginBtn from "../user/MobileLoginBtn";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import ContentWithFooter from "../layouts/ContentWithFooter";

const EmptyDashboard = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  return (
    <ContentWithFooter>
      <NonIdealState
        icon={IconNames.HEART_BROKEN}
        action={
          isWebViewFromAndroid() ? (
            <MobileLoginBtn />
          ) : (
            <Button
              className="text-2xl md:text-base"
              onClick={() => loginWithRedirect()}
              text={t("labels.log_in")}
              icon={IconNames.LOG_IN}
              intent={Intent.SUCCESS}
            />
          )
        }
        description={<div className="text-2xl md:text-base">{t("warnings.empty_dashboard")}</div>}
      />
    </ContentWithFooter>
  );
};

export default EmptyDashboard;
