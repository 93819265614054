import React from "react";

type Props = {
  className?: string;
};

const AppName = ({ className }: Props) => {
  return <span className={`${className}`}>Budget 4 you</span>;
};

export default AppName;
