import gql from "graphql-tag";

export const CREATE_TRIAL_SUBSCRIPTION = gql`
  mutation CreateTrialSubscription($objects: [subscriptions_insert_input!]!) {
    insert_subscriptions(objects: $objects) {
      affected_rows
    }
    __typename
  }
`;
