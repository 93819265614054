import gql from "graphql-tag";
import { ESTIMATE_ENTRY_FRAGMENT } from "../fragments/estimate_entries";

export const ESTIMATE = gql`
  ${ESTIMATE_ENTRY_FRAGMENT}
  query Estimate($estimateId: Int!) {
    estimates_by_pk(id: $estimateId) {
      id
      name
      estimate_entries(order_by: { planned: desc }) {
        ...EstimateEntryFragment
      }
    }
  }
`;

export const ESTIMATES = gql`
  query Estimates {
    estimates(order_by: { name: asc }) {
      id
      name
    }
  }
`;
