import _ from "lodash";
import i18n from "../../i18n";
import { ISplittedEntry, validationFunction } from "../../types/types";

// All validators should return [ isValid: false, errMessage: string ]

export type TValidationResult = [boolean, string | null];

export type TValidationResults = {
  [key: string]: TValidationResult;
};

export type TValidationParams = {
  [key: string]: (value: any) => TValidationResult;
};

export const getValidationErrorMessage = (results: TValidationResults, key: string) => {
  return _.isEmpty(results) ? "" : _.isEmpty(results[key]) ? "" : _.last(results[key]);
};

export const validateForm = (
  obj: object,
  fieldsToValidate: TValidationParams
): TValidationResults =>
  _.reduce(
    fieldsToValidate,
    (acc, validator, fieldKey) => {
      // In some form e.g Entry we have conditional displaying splitted entry or just category for transfer
      // TODO rename it to be more descriptive (like Target account or sth)
      if (fieldKey === "splitted_entries" && _.has(obj, "transfer") && _.get(obj, "transfer")) {
        return acc;
      }
      if (fieldKey === "category" && _.has(obj, "transfer") && _.get(obj, "transfer") === false) {
        return acc;
      }
      //@ts-ignore
      const res = validator(obj[fieldKey]);
      //@ts-ignore
      if (!res[0]) {
        //@ts-ignore
        acc[fieldKey] = res;
      }
      return acc;
    },
    {}
  );

export const combineValidators =
  (...validators: any[]): validationFunction =>
  (value: any): TValidationResult =>
    _.reduce(validators, (acc, validator) => (acc[0] ? validator(value) : acc), [true, null]);

export const isRequired = (value: any): TValidationResult => {
  if (value instanceof Date && !_.isNaN(value)) {
    return [true, ""];
  }
  if (typeof value === "number" ? _.isNaN(value) : _.isEmpty(value)) {
    return [false, "This field is required"];
  }
  return [true, ""];
};

export const isNumber = (value: number): TValidationResult => {
  if (_.isNumber(+value) && !_.isNaN(+value)) {
    return [true, ""];
  }

  return [false, i18n.t("errors.validations.not_a_number")];
};

export const splittedEntriesValidator = (value: ISplittedEntry[]): TValidationResult => {
  if (!_.isEmpty(value)) {
    const allValid = _.every(value, (entry: ISplittedEntry) => {
      return !_.isEmpty(entry.category) && _.isNumber(+entry.value) && !_.isNaN(+entry.value);
    });
    if (allValid) {
      return [true, ""];
    }
  }
  return [false, "This field cannot be empty"];
};
