import gql from "graphql-tag";

export const CREATE_ACCOUNT = gql`
  mutation createAccount($object: accounts_insert_input!) {
    insert_accounts_one(object: $object) {
      id
      name
      initial_amount
      balance
      main_account
      budget_id
      currency
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount(
    $id: Int!
    $name: String
    $initial_amount: numeric
    $balance: numeric
    $main_account: Boolean
    $currency: String
  ) {
    update_accounts_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        initial_amount: $initial_amount
        balance: $balance
        main_account: $main_account
        currency: $currency
      }
    ) {
      id
      name
      initial_amount
      balance
      main_account
      currency
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($id: Int!) {
    delete_accounts_by_pk(id: $id) {
      id
    }
  }
`;
