import { Button, Classes, Dialog, IconName, Intent, MaybeElement } from "@blueprintjs/core";
import React, { ReactNode } from "react";

interface Props {
  applyLabel?: string;
  cancelLabel?: string;
  icon: IconName | MaybeElement;
  isOpen: boolean;
  message: ReactNode;
  onApply: () => void;
  onCancel: () => void;
  title: ReactNode;
}

export const ConfirmDialog = ({
  applyLabel = "Apply",
  cancelLabel = "Cancel",
  icon,
  isOpen,
  message,
  onApply,
  onCancel,
  title,
}: Props) => {
  return (
    <Dialog icon={icon} usePortal title={title} isOpen={isOpen}>
      <div className={Classes.DIALOG_BODY}>{message}</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onCancel}>{cancelLabel}</Button>
          <Button onClick={onApply} intent={Intent.PRIMARY}>
            {applyLabel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
