import gql from "graphql-tag";

export const ESTIMATE_ENTRY_FRAGMENT = gql`
  fragment EstimateEntryFragment on estimate_entries {
    id
    name
    deleted
    planned
    real
    category {
      id
      name
    }
  }
`;
