import { FORM_INPUTS } from "../../../utils/constants";
import { combineValidators, isNumber, isRequired } from "../validators";

const bugdetEntrySchema = {
  name: "forms.labels.budget_entry",
  validators: {
    category: isRequired,
    value: combineValidators(isRequired, isNumber),
  },
  fields: {
    category: {
      label: "forms.labels.category",
      required: true,
      type: FORM_INPUTS.CATEGORIES_SELECT,
    },
    value: {
      required: true,
      label: "forms.labels.value",
      type: FORM_INPUTS.NUMBER,
    },
    name: {
      label: "forms.labels.description",
      type: FORM_INPUTS.RICH_TEXT,
    },
  },
};

export default bugdetEntrySchema;
