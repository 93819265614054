import { isRequired, isNumber, combineValidators } from "../validators";
import { FORM_INPUTS } from "../../../utils/constants";

const incomeSchema = {
  name: "forms.labels.income",
  validators: {
    name: isRequired,
    value: combineValidators(isRequired, isNumber),
    actual_value: combineValidators(isRequired, isNumber),
  },
  fields: {
    name: {
      required: true,
      label: "forms.labels.income_name",
      tooltip: "forms.tooltips.income_name",
      type: FORM_INPUTS.TEXT,
    },
    value: {
      required: true,
      label: "forms.labels.planned_amount",
      tooltip: "forms.tooltips.planned_amount",
      type: FORM_INPUTS.NUMBER,
    },
    actual_value: {
      required: true,
      label: "forms.labels.actual_amount",
      tooltip: "forms.tooltips.actual_amount",
      type: FORM_INPUTS.NUMBER,
    },
  },
};

export default incomeSchema;
