import gql from "graphql-tag";

export const INCOMES = gql`
  query Incomes($budgetId: Int!) {
    incomes(order_by: { value: desc }, where: { budget_id: { _eq: $budgetId } }) {
      actual_value
      id
      name
      value
      income_date
      deleted_at
    }
  }
`;
