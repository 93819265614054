import gql from "graphql-tag";
import { ENTRY_FRAGMENT } from "../fragments/entries.js";

export const CATEGORY = gql`
  ${ENTRY_FRAGMENT}
  query Category($categoryId: Int!, $periodStart: timestamptz!, $periodEnd: timestamptz!) {
    categories_by_pk(id: $categoryId) {
      id
      name
      category_type
    }
    splitted_entries(
      where: {
        deleted_at: { _is_null: true }
        _and: [
          { category_id: { _eq: $categoryId } }
          { entry: { date: { _gt: $periodStart } } }
          { entry: { date: { _lt: $periodEnd } } }
        ]
      }
    ) {
      id
      value
      entry {
        ...EntryFragment
      }
    }
  }
`;

export const CATEGORY_FOR_YEAR = gql`
  ${ENTRY_FRAGMENT}
  query Category($categoryId: Int!, $periodStart: timestamptz!, $periodEnd: timestamptz!) {
    entries(
      order_by: { date: desc }
      where: {
        _and: [
          { category_id: { _eq: $categoryId } }
          { date: { _gt: $periodStart } }
          { date: { _lt: $periodEnd } }
        ]
      }
    ) {
      id
      date
      created_at
      type
      value
      transfer
      balance
      splitted_entries(where: { deleted_at: { _is_null: true } }) {
        id
      }
    }
    splitted_entries(
      where: {
        deleted_at: { _is_null: true }
        _and: [
          { category_id: { _eq: $categoryId } }
          { entry: { date: { _gt: $periodStart } } }
          { entry: { date: { _lt: $periodEnd } } }
        ]
      }
    ) {
      id
      value
      entry {
        id
        date
        type
      }
    }
  }
`;

export const CATEGORIES_LIST = gql`
  query Categories($budgetId: Int!) {
    categories(order_by: { name: asc }, where: { budget_id: { _eq: $budgetId } }) {
      id
      name
      parent_category
      category_type
    }
  }
`;

export const CATEGORIES = gql`
  ${ENTRY_FRAGMENT}
  query Categories($budgetId: Int!) {
    categories(order_by: { name: asc }, where: { budget_id: { _eq: $budgetId } }) {
      id
      name
      parent_category
      category_type
      entries(order_by: { date: desc }) {
        ...EntryFragment
      }
    }
  }
`;

export const CATEGORIES_BY_ACCOUNT_ID = gql`
  query CategoriesByAccountId($accountId: Int!, $budgetId: Int!) {
    categories(where: { parent_account: { _eq: $accountId } }) {
      id
      name
      category_type
    }
  }
`;

export const CATEGORIES_BY_NAME = gql`
  query CategoriesByName($categoryName: String, $budgetId: Int!) {
    categories(where: { name: { _eq: $categoryName }, _and: [{ budget_id: { _eq: $budgetId } }] }) {
      id
      name
      category_type
    }
  }
`;
