import { isRequired, isNumber, combineValidators, splittedEntriesValidator } from "../validators";
import { FORM_INPUTS, ENTRY_TYPES } from "../../../utils/constants";

const entrySchema = {
  name: "forms.labels.entry",
  validators: {
    account: isRequired,
    date: isRequired,
    type: isRequired,
    splitted_entries: splittedEntriesValidator,
    category: isRequired,
    value: combineValidators(isRequired, isNumber),
  },
  fields: {
    transferSwitch: {
      label: "forms.labels.transfer_money",
      type: FORM_INPUTS.TRANSFER_SWITCH,
    },
    account_type: [
      {
        key: "account",
        required: true,
        label: "forms.labels.account",
        type: FORM_INPUTS.ACCOUNT_SELECT,
      },
      {
        key: "type",
        label: "forms.labels.category_type",
        type: FORM_INPUTS.RADIO_BUTTONS,
        options: [
          { value: ENTRY_TYPES.EXPENSE, text: "forms.labels.expense" },
          { value: ENTRY_TYPES.INCOME, text: "forms.labels.income" },
        ],
      },
    ],
    date: {
      required: true,
      key: "date",
      label: "forms.labels.date",
      type: FORM_INPUTS.DATE_INPUT,
      placeholder: "cccc dd/MM/yyyy",
      format: "cccc dd/MM/yyyy",
    },
    splitted_entries: {
      label: "forms.labels.value_and_category",
      required: true,
      type: FORM_INPUTS.MULTIENTRIES,
    },
    category: {
      label: "forms.labels.target_account",
      required: true,
      type: FORM_INPUTS.CATEGORIES_SELECT,
    },
    payee: {
      label: "forms.labels.payee",
      type: FORM_INPUTS.PAYEE_SELECT,
    },
    value: {
      required: true,
      label: "forms.labels.amount",
      type: FORM_INPUTS.NUMBER,
    },
    description: {
      label: "forms.labels.description",
      type: FORM_INPUTS.RICH_TEXT,
    },
  },
};

export default entrySchema;
