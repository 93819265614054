import gql from "graphql-tag";

export const CREATE_BUDGET = gql`
  mutation createBudget($object: budgets_insert_input!) {
    insert_budgets_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_BUDGET = gql`
  mutation updateBudget($id: Int!, $name: String, $initial_amount: numeric, $balance: numeric) {
    update_budgets_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, initial_amount: $initial_amount, balance: $balance }
    ) {
      id
      name
      initial_amount
      balance
    }
  }
`;

export const DELETE_BUDGET = gql`
  mutation deleteBudget($id: Int!) {
    delete_budgets_by_pk(id: $id) {
      id
    }
  }
`;

export const SOFT_DELETE_BUDGET = gql`
  mutation softDeleteBudget($id: Int!) {
    update_budgets_by_pk(pk_columns: { id: $id }, _set: { soft_deleted_at: "now()" }) {
      id
    }
  }
`;
