import { gql, useMutation } from "@apollo/client";
import { IAccount } from "../../types/types";
import { CREATE_GOAL, DELETE_GOAL } from "../mutations/goal";
import { GOALS } from "../queries/goal";

export const useDeleteGoal = () => {
  return useMutation(DELETE_GOAL, {
    update(cache, { data: { delete_accounts_by_pk } }) {
      const data: { accounts: IAccount[] } | null = cache.readQuery({ query: GOALS });
      if (data) {
        const newAccountsList = data.accounts.filter(({ id }) => id !== delete_accounts_by_pk.id);
        cache.writeQuery({
          query: GOALS,
          data: {
            accounts: newAccountsList,
          },
        });
      }
    },
  });
};

export const useCreateGoal = () => {
  return useMutation(CREATE_GOAL, {
    update(cache, { data: { insert_accounts_one } }) {
      cache.modify({
        fields: {
          accounts(existingAccounts = []) {
            const newAccountRef = cache.writeFragment({
              data: insert_accounts_one,
              fragment: gql`
                fragment NewAccount on accounts {
                  id
                  name
                  initial_amount
                  balance
                  main_account
                  currency
                }
              `,
            });
            return [...existingAccounts, newAccountRef];
          },
        },
      });
    },
  });
};
