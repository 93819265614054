export type TBudgetSection = {
  section_name: string;
  entries: {
    name: string;
    value: number;
  }[];
};

const Housing: TBudgetSection = {
  section_name: "housing",
  entries: [
    {
      name: "rent",
      value: 0,
    },
    {
      name: "mortgage",
      value: 0,
    },
    {
      name: "insurance",
      value: 0,
    },
    {
      name: "utilities",
      value: 0,
    },
    {
      name: "maintenance",
      value: 0,
    },
    {
      name: "other-unexpected-house-related",
      value: 0,
    },
  ],
};
const Bills: TBudgetSection = {
  section_name: "bills",
  entries: [
    {
      name: "phone",
      value: 0,
    },
    {
      name: "internet",
      value: 0,
    },
    {
      name: "tv",
      value: 0,
    },
    {
      name: "subscriptions",
      value: 0,
    },
    {
      name: "other-bills",
      value: 0,
    },
  ],
};
const Groceries: TBudgetSection = {
  section_name: "groceries",
  entries: [
    {
      name: "food",
      value: 0,
    },
    {
      name: "drinks",
      value: 0,
    },
    {
      name: "other-groceries",
      value: 0,
    },
  ],
};

const Investments: TBudgetSection = {
  section_name: "investments",
  entries: [
    {
      name: "stocks",
      value: 0,
    },
    {
      name: "bonds",
      value: 0,
    },
    {
      name: "other-savings",
      value: 0,
    },
  ],
};

const Childcare: TBudgetSection = {
  section_name: "childcare",
  entries: [
    {
      name: "school",
      value: 0,
    },
    {
      name: "babysitter",
      value: 0,
    },
    {
      name: "other-kids-related",
      value: 0,
    },
  ],
};

const Regular: TBudgetSection = {
  section_name: "regular",
  entries: [
    {
      name: "transportation",
      value: 0,
    },
    {
      name: "health",
      value: 0,
    },
    {
      name: "clothing",
      value: 0,
    },
    {
      name: "entertainment",
      value: 0,
    },
    {
      name: "other-regular-expenses",
      value: 0,
    },
  ],
};

const Miscellaneous: TBudgetSection = {
  section_name: "miscellaneous",
  entries: [
    {
      name: "gifts",
      value: 0,
    },
    {
      name: "charity",
      value: 0,
    },
    {
      name: "other-unexpected",
      value: 0,
    },
  ],
};

export const BUDGET_SECTIONS_MAP: Record<string, TBudgetSection[]> = {
  family_budget: [Housing, Bills, Groceries, Investments, Childcare, Regular, Miscellaneous],
  dink: [Housing, Regular, Investments, Childcare, Miscellaneous],
  sink: [Housing, Bills, Groceries, Investments, Regular, Miscellaneous],
  empty_budget: [],
};
