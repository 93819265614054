import gql from "graphql-tag";
import { BUDGET_ENTRY_FRAGMENT } from "../queries/budgetEntries";

export const BUDGET = gql`
  ${BUDGET_ENTRY_FRAGMENT}
  query Budget($budgetId: Int!) {
    budgets_by_pk(id: $budgetId) {
      id
      created_at
    }
  }
`;
