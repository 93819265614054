import React, { useContext, useMemo } from "react";
import _ from "lodash";
import { IAccount } from "../../types/types";
import FormBuilder from "./FormBuilder";
import AccountSchema from "./schemas/account";
import { UserContext } from "../WithUserContext";

const AccountForm = ({
  account,
  onApply,
  onCancel,
  onChange,
}: {
  account: IAccount;
  onApply: (account: IAccount) => Promise<any>;
  onCancel: () => void;
  onChange: (key: string, value: any) => void;
}) => {
  const userData = useContext(UserContext);
  const schema = useMemo(() => {
    if (userData?.settings.premiumFeatures) {
      return AccountSchema;
    } else {
      return _.extend({}, AccountSchema, { fields: _.omit(AccountSchema.fields, "currency") });
    }
  }, [userData]);

  return (
    <div className="m-4">
      <FormBuilder
        formSchema={schema}
        entity={account}
        onChange={onChange}
        onApply={onApply}
        onCancel={onCancel}
        additionalOptions={{}}
      />
    </div>
  );
};
export default AccountForm;
