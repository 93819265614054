import gql from "graphql-tag";

export const CREATE_ESTIMATE_ENTRY = gql`
  mutation createEstimateEntry($object: estimate_entries_insert_input!) {
    insert_estimate_entries_one(object: $object) {
      id
      estimate_id
      name
      planned
      real
    }
  }
`;

export const UPDATE_ESTIMATE_ENTRY = gql`
  mutation updateEstimateEntry($id: Int!, $name: String, $planned: numeric, $real: numeric) {
    update_estimate_entries_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, planned: $planned, real: $real }
    ) {
      id
      name
      planned
      real
    }
  }
`;

export const DELETE_ESTIMATE_ENTRY = gql`
  mutation deleteEstimateEntry($id: Int!) {
    delete_estimate_entries_by_pk(id: $id) {
      id
    }
  }
`;
