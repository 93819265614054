import gql from "graphql-tag";

export const CREATE_INCOME = gql`
  mutation createIncome($object: incomes_insert_input!) {
    insert_incomes_one(object: $object) {
      id
      name
    }
  }
`;

export const UPDATE_INCOME = gql`
  mutation updateIncome(
    $id: Int!
    $name: String
    $budget_id: Int
    $value: numeric
    $actual_value: numeric
    $deleted_at: timestamptz
  ) {
    update_incomes_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        value: $value
        actual_value: $actual_value
        budget_id: $budget_id
        deleted_at: $deleted_at
      }
    ) {
      id
      name
      value
    }
  }
`;

export const SOFT_DELETE_INCOME = gql`
  mutation updateIncome($id: Int!, $deleted_at: timestamptz) {
    update_incomes_by_pk(pk_columns: { id: $id }, _set: { deleted_at: $deleted_at }) {
      id
    }
  }
`;

export const DELETE_INCOME = gql`
  mutation deleteIncome($id: Int!) {
    delete_incomes_by_pk(id: $id) {
      id
    }
  }
`;

export const INLINE_UPDATE_INCOME = gql`
  mutation updateIncome($id: Int!, $changes: incomes_set_input) {
    update_incomes_by_pk(pk_columns: { id: $id }, _set: $changes) {
      id
      name
      value
    }
  }
`;
