import { Button, Intent } from "@blueprintjs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface IDialogFooterProps {
  onCancel:
    | (((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  onApply:
    | (((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  cancelLabel?: string;
  applyLabel?: string;
  applyEnabled?: boolean;
  saving?: boolean;
}

const DialogFooter: FC<IDialogFooterProps> = ({
  onCancel,
  onApply,
  cancelLabel,
  applyLabel,
  applyEnabled,
  saving,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bp5-dialog-footer">
      <div className="bp5-dialog-footer-actions">
        <Button onClick={onCancel}>{cancelLabel || t("labels.cancel")}</Button>
        <Button
          disabled={!applyEnabled || saving}
          loading={saving}
          type="submit"
          intent={Intent.PRIMARY}
          onClick={onApply}
        >
          {applyLabel || t("labels.apply")}
        </Button>
      </div>
    </div>
  );
};

export default DialogFooter;
