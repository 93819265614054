import React from "react";
import { Trans } from "react-i18next";

const Quotes = () => {
  return (
    <div className="md:flex flex-row items-center mt-24 mb-24">
      <div className="flex-1 text-xl">
        <Trans i18nKey="main_page.books.text">
          <div className="text-center">
            <strong className="text-2xl mb-4 block">Mark Twain:</strong>

            <i>The man who does not read has no advantage over the man who cannot read.</i>
          </div>
        </Trans>
      </div>
    </div>
  );
};

export default Quotes;
