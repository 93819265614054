import _ from "lodash";
import { DateTime } from "luxon";
import { useContext } from "react";
import { UserContext } from "./WithUserContext";

export const useSubscriptionData: () => {
  status: string | undefined;
  trialEnd: number | false;
  trialEnded: boolean;
  subscriptionEnd: DateTime;
  hasValidSubscription: boolean;
} = () => {
  const userData = useContext(UserContext);
  const subscription = _.first(userData?.subscriptions || []);
  const subscriptionEnd: DateTime = DateTime.fromISO(
    (subscription?.subscription_end as string) || ""
  );

  const trialEnd =
    subscription?.status === "trialing"
      ? Math.floor(
          DateTime.now().diff(
            // @ts-ignore
            DateTime.fromISO(subscription.subscription_end),
            "days"
          ).days
        )
      : false;
  return {
    status: subscription?.status,
    trialEnd,
    trialEnded: Number(trialEnd) > 0,
    subscriptionEnd,
    hasValidSubscription:
      (userData?.subscriptions?.length || 0) > 0 &&
      subscriptionEnd > DateTime.now() &&
      !subscription?.cancelled,
  };
};
